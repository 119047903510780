@import "../variables.scss";

.footer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: black;
  padding: 60px;
  color: $primary-background;
  scroll-snap-align: start;

  &__intro {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    &-one {
      display: flex;
      flex-direction: column;
      gap: 20px;
      img {
        width: 150px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 100px;

    a {
      text-decoration: none;
      color: $primary-background;
      &:hover {
        color: $primary-accent;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      gap: 10px;
      ul {
        list-style: none;
      }
    }

    &-social {
      display: flex;
      flex-direction: row;
      gap: 35px;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 60px;
    }
  }

  &__copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-accent {
      color: $secondary-accent;

      &-blog {
        font-size: 12px;
        color: $primary-blue;
        text-decoration: none;
        margin-top: 10px;
      }
    }
  }
}
