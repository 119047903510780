@import "../variables.scss";

.hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 70%;
  min-height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  object-fit: contain;
  padding: 60px;
  margin-bottom: 60px;
  scroll-snap-align: end;

  &__heading {
    color: $font-dark;
    font-size: 80px;
    max-width: 45vw;
  }

  &__subheading {
    font-size: 24px;
    max-width: 40vw;
    color: $primary-blue;
    margin: 20px 0;
  }

  &__subheading {
    margin-top: 10px;
  }
}

@media screen and (max-width: $medium-desktop) {
  .hero {
    &__heading {
      font-size: 70px;
    }

    &__subheading {
      font-size: 20px;
      max-width: 35vw;
    }
  }
}

@media screen and (max-width: $large-tablet) {
  .hero {
    background-image: none !important;
    background: $primary-background;
    box-shadow: none;
    &__heading {
      font-size: 70px;
      max-width: 100vw;
    }

    &__subheading {
      font-size: 20px;
      max-width: 70vw;
    }
  }
}

@media screen and (max-width: $medium-mobile) {
  .hero {
    min-height: 86vh;
    justify-content: center;
    align-items: center;
    &__heading {
      text-align: center;
      font-size: 44px;
      max-width: 100vw;
    }

    &__subheading {
      text-align: center;
      font-size: 18px;
      max-width: 100vw;
    }

    &__button {
      align-self: center;
    }
  }
}
