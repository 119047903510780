@import "../../../components/variables.scss";

.single-post {
  display: flex;
  flex-direction: column;
  padding: 60px;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  &__image {
    width: 100%;
    height: 500px;
    border-radius: 5px;
    object-fit: cover;
    object-position: 0 30%;
    scroll-snap-align: end;
  }
  h3,
  h4 {
    scroll-snap-type: start;
  }

  &__heading {
    text-align: center;
    margin: 60px 0 40px 0;
    font-size: 44px;
    scroll-snap-align: center;
  }

  a {
    color: #708fc2;
  }
}
.wmde-markdown-var.w-md-editor {
  height: 100% !important;
}
