@import "../variables.scss";

.services-we-offer-section {
  height: 100vh;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 500px;
  scroll-snap-align: center;
  padding: 60px;
  justify-content: space-around;
  gap: 20px;

  &__text {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    h2 {
      margin: 0;
      color: $font-gray;
      font-size: 56px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -2px;
      line-height: 1.2;
    }
    h3 {
      margin: 0;
      color: $font-gray;
      font-size: 22px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.2;
    }
  }
  div {
    display: flex;
    width: auto;
    height: 60vh;
    position: relative;
    max-height: 90vh;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    text-align: center;
  }
  img {
    width: 45vw;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
  }
}

// .panel-1 {
//   background-image: linear-gradient(#56729692, #b0c4cf);
// }

// .panel-2 {
//   background-image: linear-gradient(#b0c4cf, #cedce2);
// }

// .panel-3 {
//   background-image: linear-gradient(#cedce2, #f5f9fd);
// }

.panel-1 {
  background-image: linear-gradient(#9dacb3, #c8d3da);
  padding-top: 118px;
}

.panel-2 {
  background-image: linear-gradient(#c8d3da, #e8edf1);
  padding-top: 118px;
}

.panel-3 {
  background-image: linear-gradient(#e8edf1, #f5f9fd);
  padding-top: 118px;
}

@media screen and (max-width: $medium-tablet) {
  .services-we-offer-section {
    flex-direction: column;
    scroll-snap-align: end;
    height: calc(100vh - 118px);
    &__text {
      h2 {
        font-size: 40px;
      }
      h3 {
        font-size: 22px;
      }
    }
    img {
      width: calc(100vw - 120px);
    }
  }
  .panel-1,
  .panel-2,
  .panel-3 {
    padding-top: 60px;
  }
}

@media screen and (max-width: $medium-mobile) {
  .services-we-offer-section {
    &__text {
      gap: 15px;
      h2 {
        font-size: 24px;
        letter-spacing: -1px;
      }
      h3 {
        font-size: 16px;
      }
    }
    img {
      height: 100%;
    }
  }
}
