@import "../variables.scss";

.how-it-works-container {
  box-shadow: inset 0 7px 15px -7px rgba(56, 84, 131, 50%);
  // padding: 150px 0 100px 0;
  scroll-snap-align: end;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.how-it-works-heading {
  font-size: 58px;
  padding-left: 80px;
  padding-bottom: 0;
  margin-bottom: -50px;
  color: $font-blue;
  margin-top: 40px;
}
.how-it-works {
  padding: 0 60px 0 60px;
  padding-left: 40px;
  color: $font-gray;
  display: flex;
  flex-direction: row;
  gap: 100px;
  justify-content: space-between;
  align-items: center;

  &__tab {
    display: flex;
    flex-direction: column;
    gap: 60px;

    &-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-left: 20px;
      cursor: pointer;

      &-heading {
        font-size: 32px;

        &:hover {
          color: $primary-accent;
        }
      }
      &-info {
        font-size: 20px;

        &:hover {
          color: $primary-blue;
        }
      }
    }

    &-section.active {
      border-left: 3px solid $font-gray;
      opacity: 1;
    }

    &-image {
      height: 650px;
      display: flex;
      justify-content: flex-end;
      transition: left 0.3s ease;

      img {
        object-fit: cover;
        width: 40vw;
        transition: 1s ease;
        transition: left 0.3s ease;
      }
    }
  }
}

@media screen and (max-width: $medium-tablet) {
  .how-it-works-heading {
    padding: 0;
    text-align: center;
    margin-bottom: 0;
    font-size: 44px;
  }
  .how-it-works {
    padding-top: 40px;

    &__tab {
      gap: 40px;
      &-section {
        &-heading {
          font-size: 24px;
        }
        &-info {
          font-size: 16px;
        }
      }
      &-image {
        height: 80vh;
      }
    }
  }
}

@media screen and (max-width: $small-tablet) {
  .how-it-works {
    gap: 30px;
    &__tab {
      gap: 30px;
      &-section {
        &-heading {
          font-size: 22px;
        }
        &-info {
          font-size: 14px;
        }
      }

      &-image {
        height: 70vh;
        img {
          max-width: 45vw;
        }
      }
    }
  }
}

@media screen and (max-width: $medium-mobile) {
  .how-it-works {
    flex-direction: column;
    gap: 60px;
    padding: 60px;
    &__tab {
      gap: 30px;
      &-section {
        &-heading {
          font-size: 22px;
        }
        &-info {
          font-size: 14px;
        }
      }

      &-image {
        min-width: 100vw;
        max-width: 100vw;
        height: 40vh;
        justify-content: center;

        img {
          min-width: 60vw;
        }
      }
    }
  }
}
