@import "../../components/variables.scss";
.hair-loss {
  scroll-snap-stop: always;
}
.what-to-expect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 80px;
  padding: 60px;
  margin: 60px 0;
  align-items: center;
  color: $font-gray;
  scroll-snap-align: center;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    h1 {
      font-size: 44px;
    }
    p {
      font-size: 28px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 60px;
    align-items: center;

    &-column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;

      h3 {
        font-size: 28px;
      }
    }
  }

  &__logo {
    border: solid 8px rgba($primary-accent, 0.5);
    border-radius: 50%;
    width: 130px;
    height: 130px;
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      width: 60px;
    }
  }
}

@media screen and (max-width: $small-tablet) {
  .what-to-expect {
    &__row {
      flex-wrap: wrap;
    }
  }
}
