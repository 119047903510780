@import "../../components/variables.scss";

.blog-container {
  // scroll-snap-align: end;
  overflow: visible;
}

.blog {
  scroll-snap-align: end;
  &__header {
    scroll-snap-align: end;
    padding: 60px;
    padding-bottom: 0;
  }

  &__categories {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 60px;
    margin-top: 30px;
    flex-wrap: wrap;
    padding: 0 60px;

    &-category {
      padding: 10px 20px;
      background: $primary-accent;
      color: $primary-blue;
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid $primary-accent;
      transition: 0.5s ease;

      &:hover {
        background: none;
        border: 1px solid $primary-accent;
        transition: 0 5s ease;
      }
    }
  }

  &__posts {
    margin-top: 60px;
    scroll-snap-align: center;
    padding: 0 60px;
  }
}
