@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "../variables.scss";
.testimonials-slider {
  margin: 0 auto;
  // padding: 40px;
  color: #333;
  background: $primary-background;
  overflow: hidden;
  scroll-snap-align: end;
  a {
    all: unset;
    cursor: pointer;
  }
  &__intro {
    padding: 10px 60px 0 60px;
    color: $font-blue;
    font-size: 36px;
    font-weight: 700;
  }
  &__row {
    padding: 0px 8px;
    margin: 60px 60px 160px 60px;
    z-index: 1;
  }
  &__card {
    margin: 5px;
    box-shadow: inset 0 7px 15px -7px #e0cba680;
    border-radius: 20px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-reviewer {
      display: flex;
      flex-direction: row;
      gap: 25px;
      margin-top: 15px;

      &-image {
        width: 45px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }

  &__waves {
    background: $primary-accent;
    height: 10%;
    width: 100%;
    position: relative;

    &-layer {
      background: url("../../assets/testimonials_wave.svg") repeat-x;
      position: absolute;
      top: -198px;
      width: 6400px;
      height: 198px;
      animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      transform: translate3d(0, 0, 0);

      &:nth-of-type(2) {
        top: -155px;
        animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
          swell 10s ease -1.25s infinite;
        opacity: 1;
      }
    }
  }

  &__ending {
    height: 210px;
    background-color: $primary-accent;
    background-image: linear-gradient($primary-accent 12%, $primary-background);
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.slick-prev:before,
.slick-next:before {
  color: $primary-accent;
  font-size: 24px;
}

@media screen and (max-width: $small-tablet) {
  .testimonials-slider {
    &__intro {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $medium-mobile) {
  .testimonials-slider {
    &__row {
      margin: 60px 30px 80px 30px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
    visibility: hidden;
  }
}
