@import "../variables.scss";

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: auto;
}

.btn--primary {
  background-color: $primary-blue;
  color: aliceblue;
  border: 1px solid $primary-blue;
  transition: all 0.5s ease-out;
}

.bnt--outline {
  background-color: transparent;
  color: $primary-background;
  padding: 8px 20px;
  border: 1px solid $primary-blue;
  transition: all 0.5s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
  transition: all 0.5s ease-out;
}

.btn--large {
  padding: 12px 26px;
  font-size: 18px;
  transition: all 0.5s ease-out;
}

.btn--medium:hover,
.btn--large:hover {
  background-color: $primary-background;
  color: $primary-blue;
  transition: all 0.5s ease-out;
}
