@import "../variables.scss";

/* Primary Text Panels */

.text-panel {
  &__left {
    display: flex;
    flex-direction: row;
    padding: 60px;
    background-color: $primary-background;
    gap: 100px;
    scroll-snap-align: center;
  }
  &__right {
    display: flex;
    flex-direction: row-reverse;
    padding: 60px;
    background-color: $primary-background;
    gap: 100px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__accent-line {
    border-bottom: $primary-accent 5px solid;
    border-radius: 40px;
    width: 80px;
    margin-bottom: 30px;
  }

  &__heading {
    font-size: 44px;
    color: $font-gray;
  }

  &__subheading {
    font-size: 22px;
    color: $tertiary-blue;
  }

  &__paragraph {
    font-size: 16px;
    color: $font-light;
  }

  &__image-container {
    max-height: 400px;
    min-height: 400px;
    max-width: 40vw;
    min-width: 40vw;
  }
}

@media screen and (max-width: $medium-desktop) {
  .text-panel {
    &__left {
      flex-direction: column-reverse;
      gap: 60px;
    }

    &__text-container {
      margin-top: 20px;
    }

    &__image-container {
      height: 300px;
      max-width: 100vw;
    }

    // &__image {
    //   width: 100%;
    //   object-fit: cover;
    // }
  }
}

@media screen and (max-width: $medium-tablet) {
  .text-panel {
    &__image-container {
      min-height: 200px;
      height: 250px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: $medium-mobile) {
  .text-panel {
    &__heading{ 
      font-size: 30px;
    }
    &__subheading {
      font-size: 16px;
    }
  }
}

/* Secondary Text Panels */

.secondary-text-panel {
  &__left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 60px;
    background-color: $primary-background;
    gap: 60px;
    scroll-snap-align: end;
  }

  &__right {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 60px;
    background-color: $primary-background;
    gap: 60px;
    scroll-snap-align: end;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__heading {
    font-size: 42px;
    color: $font-gray;
  }

  &__paragraph {
    font-size: 18px;
    color: $font-light;
    margin-bottom: 20px;
    font-weight: 500;
  }

  &__image-container {
    // height: 300px;
    max-height: 350px;
    min-height: 350px;
    max-width: 38vw;
    min-width: 38vw;

    // img {
    //   height: 100%;
    //   width: 32vw;
    //   object-fit: cover;
    // }
  }

  &__accent-line {
    border-bottom: $primary-accent 5px solid;
    border-radius: 40px;
    width: 80px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: $medium-desktop) {
  .secondary-text-panel {
    &__left {
      flex-direction: column-reverse;
      gap: 60px;
    }

    &__right {
      flex-direction: column-reverse;
      gap: 60px;
    }

    &__image-container {
      height: 300px;
      max-width: 100vw;

      // img {
      //   width: 100%;
      //   object-fit: cover;
      // }
    }
  }
}



.text-panel-container {
  padding: 60px 0;
  box-shadow: inset 0 7px 15px -7px rgba(56, 84, 131, 0.3);
}

.home-text-panel-container {
  box-shadow: none;
}
@media screen and (max-width: $medium-tablet) {
  .btn--primary {
    width: 100%;
  }
  .text-panel-container {
    scroll-snap-align: end;
    padding: 0;
  }
  .text-panel{
    &__left, &__right {
    gap: 20px;
  }
  &__accent-line {    
    margin-bottom: 0;
  }

 
}
}

@media screen and (max-width: $medium-mobile) {
  .secondary-text-panel {
    &__heading{ 
      font-size: 30px;
    }
    &__subheading {
      font-size: 14px;
    }
    &__paragraph {
      font-size: 16px;
    }
    &__image-container {
      min-height: 200px;
      height: 250px;
      border-radius: 10px;
    }
  }
}
