@import "../variables.scss";

.prefooter {
  display: flex;
  flex-direction: column;
  padding: 60px;
  color: $font-gray;
  gap: 100px;
  scroll-snap-align: center;

  &__services {
    display: flex;
    flex-direction: column;
    padding: 60px;
    color: $font-gray;
    gap: 40px;
    scroll-snap-align: end;
    padding-bottom: 70px;

    &-images {
      font-size: 0;
      text-align: center;
      display: flex;
      flex-direction: row;
      gap: 30px;

      &-container {
        text-align: left;
        opacity: 0.99;
        overflow: hidden;
        position: relative;
        border-radius: 3px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
        width: 50%;
        object-fit: cover;

        &:before {
          content: "";
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.7) 100%
          );
          width: 100%;
          height: 50%;
          opacity: 0;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 2;
          transition-property: top, opacity;
          transition-duration: 0.3s;
        }
        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }

        &-details {
          font-size: 16px;
          padding: 20px;
          color: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 3;

          span {
            display: block;
            opacity: 0;
            position: relative;
            top: 100px;
            transition-property: top, opacity;
            transition-duration: 0.3s;
            transition-delay: 0s;
          }

          .title {
            line-height: 1;
            font-weight: 600;
            font-size: 32px;
          }

          .info {
            line-height: 1.2;
            margin-top: 5px;
            font-size: 18px;
          }
        }

        &:focus,
        &:hover {
          &:before,
          span {
            opacity: 1;
          }

          &:before {
            top: 50%;
          }

          span {
            top: 0;
          }

          .title {
            transition-delay: 0.15s;
          }

          .info {
            transition-delay: 0.25s;
          }
        }
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h2 {
        font-size: 32px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  &__blog {
    display: flex;
    flex-direction: row;
    padding: 60px;
    color: $font-gray;
    gap: 100px;
    scroll-snap-align: end;
    padding-bottom: 100px;

    &-image {
      width: 60%;
      opacity: 0.8;
      transition: 0.4s ease-out;

      &:hover {
        opacity: 1;
        transform: scale(1.02);
        transition: 0.4s ease-in;
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 3px;
        transition: 0.4s ease-in;

        &:hover {
          border-radius: 0;
          transition: 0.4s ease-in;
        }
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;

      p {
        color: $primary-accent;
        font-size: 20px;
      }
      h2 {
        font-size: 32px;
      }
    }
  }

  &__ending {
    position: relative;
    scroll-snap-align: end;
    display: flex;
    min-height: calc(100vh - 118px);
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      left: 0px;
      right: 0px;
      height: 100%;
      bottom: 0px;
      background: linear-gradient(
        180deg,
        $primary-background 0%,
        rgba(139, 167, 32, 0) 20%
      );
      pointer-events: none;
    }
    &-container {
      background: url("../../assets/prefooter_ending_img.jpeg") center
        bottom/cover no-repeat;
      background-position: 0 0;
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      object-fit: contain;
      padding: 60px;
    }
  }
}

@media screen and (max-width: $small-tablet) {
  .prefooter {
    &__services {
      &-images {
        flex-direction: column;
        gap: 10px;

        &-container {
          width: 100%;
          min-height: auto;
          &-details {
            font-size: 15px;
            .title {
              font-size: 24px;
            }
            .info {
              font-size: 16px;
            }
          }
        }
      }

      &-description {
        h2 {
          font-size: 28px;
        }
        p {
          font-size: 18px;
        }
      }
    }

    &__blog {
      flex-direction: column;
      gap: 30px;
      &-image {
        width: 100%;
      }

      &-description {
        gap: 20px;
        p {
          font-size: 18px;
        }
        h2 {
          font-size: 28px;
        }
      }
    }
    &__ending {
      &-container {
        background: url("../../assets/prefooter_ending_img.jpeg") center
          bottom/contain no-repeat;
      }
    }
  }
  
}
