@import "../../components/variables.scss";

.terms-and-conditions,
.shipping-and-refund,
.privacy-policy {
  padding: 60px;
  scroll-snap-align: end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  line-height: 30px;

  &__heading {
    scroll-snap-align: end;
    font-size: $hero-heading-desktop;
    color: $font-blue;
    font-weight: 700;
    align-self: center;
    text-align: center;
  }
}

.terms-and-conditions__content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media screen and (max-width: $medium-tablet) {
  .terms-and-conditions,
  .shipping-and-refund,
  .privacy-policy {
    &__heading {
      font-size: $hero-heading-tablet;
    }
  }
}
@media screen and (max-width: $medium-mobile) {
  .terms-and-conditions,
  .shipping-and-refund,
  .privacy-policy {
    &__heading {
      font-size: $hero-heading-mobile;
    }
  }
}
