@import "../../components/variables.scss";
.home {
  overflow: visible;
}
.landing-page-hero {
  position: relative;
  scroll-snap-align: end;
  display: flex;
  min-height: calc(100vh - 118px);
  overflow: visible;

  &::after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    height: 50%;
    bottom: 0px;
    background: linear-gradient(
      180deg,
      rgba(139, 167, 32, 0) 35%,
      #9dacb3 100%
    );
    pointer-events: none;
  }
  &__container {
    // background: url("https://brbxkdbr39f0k4gd.public.blob.vercel-storage.com/hero_img-yKvjNFyRzAyhy9rxZPzgSYqajZYYXE.jpeg") center bottom/cover no-repeat;
    background: url("../../assets/hero_img.jpeg") center bottom/cover no-repeat;
    // overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    padding: 60px;
    scroll-snap-align: end;
    // border-bottom: 5px solid $primary-accent;
  }

  &__pre-intro {
    font-size: 26px;
    max-width: 75vw;
    color: $primary-accent;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 24px;
    margin: 20px 0;
    scroll-snap-align: end;
  }

  &__intro {
    color: $font-dark;
    font-size: 90px;
    max-width: 1200px;
  }

  &__subtitle {
    font-size: 28px;
    font-weight: 500;
    max-width: 75vw;
    color: $font-gray;
    margin: 20px 0;
  }
}

@media screen and (max-width: $medium-desktop) {
  .landing-page-hero {
    &__intro {
      max-width: 900px;
    }
  }
}

@media screen and (max-width: $large-tablet) {
  .landing-page-hero {
    // background-position: 60%;
    &__pre-intro {
      font-size: 22px;
      scroll-snap-align: end;
    }
    &__intro {
      font-size: 60px;
      max-width: 100vw;
    }

    &__subtitle {
      font-size: 22px;
      max-width: 50vw;
    }
  }
}

@media screen and (max-width: $small-tablet) {
  .landing-page-hero {
    min-height: none;
    &__pre-intro {
      font-size: 20px;
    }
    &__intro {
      font-size: 54px;
      max-width: 500px;
    }

    &__subtitle {
      font-size: 18px;
      max-width: 45vw;
    }
  }
}

@media screen and (max-width: $medium-mobile) {
  .landing-page-hero {
    &__container {
      // max-height: 100vh;
      // min-height: 740px;
      background-position: 80%;
      justify-content: flex-start;
      padding-top: 5vh;
    }

    &__pre-intro {
      font-size: 18px;
    }
    &__intro {
      font-size: 36px;
    }

    &__subtitle {
      display: none;
    }
  }
}
