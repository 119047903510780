@import "../../components/variables.scss";

.ed-prompt {
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: $font-gray;
  align-items: center;
  scroll-snap-align: center;
}

.ed-prompt-subsection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
