@import "../../components/variables.scss";

.about-us-subsection {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: space-between;
  padding: 100px 60px 60px 60px;
  box-shadow: inset 0 7px 15px -7px rgba(56, 84, 131, 0.3);
  scroll-snap-align: end;
  &__image {
    max-width: 50vw;
    max-height: 75vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right top;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: center;
    width: 80vw;
  }

  &__text-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__heading {
    font-size: 44px;
  }

  &__paragraph {
    font-size: 18px;
  }
}

@media screen and (max-width: $small-tablet) {
  .about-us-subsection {
    flex-direction: column;
  }
}
