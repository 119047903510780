@import "../variables.scss";

.rx-card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 10px;
  width: 27vw;
  gap: 30px;
  box-shadow: rgba(56, 84, 131, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  min-height: 790px;
  scroll-snap-align: center;

  &__image {
    img {
      width: 100%;
    }
  }

  &__primary-info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      font-size: 28px;
      color: $primary-blue;
    }

    p {
      font-size: 18px;
    }
  }

  &__divider {
    border-bottom: 1px solid black;
  }

  &__secondary-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    flex-grow: 1;
    &-usage {
      width: 32%;
      p {
        font-size: 12px;
        color: $primary-blue;
      }
    }
    &-strength {
      p {
        font-size: 12px;
        color: $primary-blue;
      }
    }
  }

  &__button {
    align-self: center;
  }
}

@media screen and (max-width: $large-tablet) {
  .rx-card {
    width: 40vw;
  }
}
@media screen and (max-width: $medium-tablet) {
  .rx-card {
    width: auto;
    height: auto;
    min-height: unset;
    img {
      height: 320px;
      object-fit: cover;
    }
    &__primary-info {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .btn--primary {
      font-size: 16px;
    }
  }
}

.rx-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-shadow: inset 0 7px 15px -7px rgba(56, 84, 131, 0.3);
  padding: 50px;
  margin-bottom: 60px;
}

.single-card {
  justify-content: space-around;
}

@media screen and (max-width: $small-tablet) {
  .rx-card {
    img {
      height: 180px;
    }
  }}