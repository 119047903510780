// Color Palette
$font-dark: #030303;
$font-blue: #021e34;
$font-gray: #333;
$font-light: #6b7094;

$primary-blue: #385483;
$secondary-blue: #282556;
$tertiary-blue: #393b6a;

$primary-accent: #e0cba6;
$secondary-accent: #ece2d0;

$primary-background: #f5f9fd;

// Viewports
// previously: $medium-mobile: 414px;
$small-mobile: 320px;
$medium-mobile: 430px;

$small-tablet: 601px;
$medium-tablet: 768px;
$large-tablet: 962px;

$medium-desktop: 1280px;

// Font Sizes
$hero-heading-small-mobile: 28px;
$hero-heading-mobile: 32px;
$hero-heading-small-tablet: 54px;
$hero-heading-tablet: 60px;
$hero-heading-desktop: 80px;

$hero-subheading-small-mobile: 16px;
$hero-subheading-mobile: 18px;
$hero-subheading-small-tablet: 20px;
$hero-subheading-tablet: 22px;
$hero-subheading-desktop: 26px;

$text-panel-heading-mobile: 32px;
$text-panel-heading-tablet: 40px;
$text-panel-heading-desktop: 44px;

$text-panel-subheading-mobile: 18px;
$text-panel-subheading-tablet: 20px;
$text-panel-subheading-desktop: 24px;

$text-panel-paragraph-mobile: 14px;
$text-panel-paragraph-tablet: 16px;
$text-panel-paragraph-desktop: 18px;

// $large-button-mobile: 33px;
// $large-button-tablet: 33px;
// $large-button-desktop: 33px;

// $medium-button-mobile: 33px;
// $medium-button-tablet: 33px;
// $medium-button-desktop: 33px;

// $small-button-mobile: 33px;
// $small-button-tablet: 33px;
// $small-button-desktop: 33px;

// $component-heading-mobile: 33px;
// $component-heading-tablet: 33px;
// $component-heading-desktop: 33px;

// $component-subheading-mobile: 33px;
// $component-subheading-mobile: 33px;
// $component-subheading-mobile: 33px;

// $component-subheading-mobile: 33px;
// $component-subheading-mobile: 33px;
// $component-subheading-mobile: 33px;
