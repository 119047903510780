@import "../../components/variables.scss";

.contact-form-div {
  display: flex;
  padding: 60px 0;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0;
  scroll-snap-align: end;

  iframe {
    position: absolute;
    float: left;
    clear: both;
    width: 100%;
    height: 550px;
    z-index: 0;
    border-radius: 50px;
  }
}

#contact-form {
  z-index: 1;
  background-color: $primary-background;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 600px;
  border-radius: 30px;
  margin-right: 60px;
  align-items: center;
}

.contact-form {
  &__heading {
    font-size: $text-panel-heading-desktop;
    color: $font-blue;
    font-weight: 700;
    text-align: left;
  }
  &__row {
    width: 100%;
    input,
    textarea {
      width: 100%;
      padding: 20px 12px;
      border: none;
      border: 1px $primary-blue solid;
      background-color: #f1f7fc;
      resize: vertical;
      color: $primary-blue;
      border-radius: 15px;
    }
  }

  &__submit {
    padding: 12px;
    width: 100%;
    border: 1px $primary-blue solid;
    background-color: #f1f7fc;
    color: $primary-blue;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    transition: all 1s ease;
    border-radius: 10px;
    &:hover {
      color: $primary-background;
      background-color: $primary-blue;
      transition: all 1s ease;
    }
  }
}

.error-message {
  color: #d1b27b;
  font-size: 14px;
  margin-top: 10px;
}

@media screen and (max-width: $large-tablet) {
  #contact-form {
    width: 470px;
  }
}

@media screen and (max-width: $medium-tablet) {
  .contact-form-div {
    flex-direction: column;
    gap: 50px;
    padding: 60px;
    iframe {
      all: unset;
      width: 100%;
      height: 300px;
    }
  }
  #contact-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin-right: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }

  .contact-form {
    &__submit {
      width: 100%;
      background-color: $primary-blue;
      color: $primary-background;
    }
  }
}

@media screen and (max-width: $medium-mobile) {
  .contact-form {
    &__heading {
      font-size: $text-panel-heading-mobile;
    }
  }
}
