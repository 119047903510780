@import "../../../components/variables.scss";
.post {
  min-width: 350px;
  // margin: 10px 25px 40px 25px;
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 60px;

  &__image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__categories {
    font-size: 12px;
    color: $primary-blue;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0 15px 0;
    display: flex;
    justify-content: center;
  }

  &__description {
    font-size: 14px;
    color: $font-gray;
    line-height: 24px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: $medium-mobile) {
  .post {
    min-width: 180px;
  }
}
