@import "../variables.scss";

.navbar {
  background: $primary-background;
  height: 118px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 999;
  padding-right: 60px;
  padding-left: 60px;
  border-bottom: $primary-accent 1px solid;
  z-index: 100000000000000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100vw;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-self: center;
}

.navbar-logo img {
  width: 124px;
}

.nav-menu {
  display: flex;
  // grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: flex-end;
  align-items: flex-start;
  transition: all 0.5s ease;
}

.nav-links {
  color: $font-blue;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: all 0.5s ease-out;
  border-bottom: 1px solid transparent;
}

.nav-links:hover {
  border-bottom: 1px solid $primary-accent;
  transition: all 0.2s ease;
}

.nav-links.active {
  border-bottom: 1px solid $primary-accent;
}

.fa-bars {
  color: $font-blue;
}

.menu-icon {
  display: none;
}

// Dropdown
.dropdown {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 15px;
  padding: 0 10px;
  border-radius: 15px;
  gap: 10px;
}

.show {
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  gap: 10px;
  padding: 15px 10px;
  transition: all 0.5s ease;
  opacity: 1;
  background-color: $primary-background;
  width: auto;
  border-radius: 15px;
  margin-top: 15px;
}

.subnav-links {
  list-style: none;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

@media screen and (max-width: $medium-desktop) {
  .navbar {
    font-size: 16px;
  }
  .nav-menu {
    margin-left: 2rem;
  }
  .navbar-logo img {
    width: 96px;
  }
}

@media screen and (max-width: $large-tablet) {
  .navbar-container {
    justify-content: flex-end;
    gap: 30px;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 118px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-left: 0;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-menu.active {
    background: $primary-blue;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1.3rem;
    width: 100%;
    display: table;
    color: $primary-background;
    text-decoration: none;
    padding-bottom: 15px;
  }

  .navbar-logo img {
    width: 124px;
    position: absolute;
    left: 60px;
  }

  .menu-icon {
    color: $secondary-blue;
    display: flex;
    // position: absolute;
    // top: 0;
    // right: 0;
    // transform: translate(-200%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: $font-blue;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: $primary-accent;
    padding: 14px 20px;
    /* border: 1px solid #fff; */
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    color: $primary-accent;
    transition: 250ms;
  }
  .dropdown {
    background-color: $primary-blue;
    padding: 0;
  }
  .show {
    background-color: $primary-blue;
    padding-bottom: 15px;
  }
}
.start-online-visit {
  // all: unset;
  background-color: $primary-blue;
  padding: 15px 30px;
  color: $primary-background;
  border-radius: 30px;
  transition: 1s ease;
  text-wrap: nowrap;
  .nav-links {
    border: none;
    color: $primary-background;
  }
  .nav-links.active {
    border: none;
  }

  &:hover {
    background-color: $primary-accent;
    transition: 1s ease;
  }
}

@media screen and (max-width: $small-tablet) {
  .navbar-container {
    gap: 15px;
  }
  .navbar-logo img {
    width: 105px;
  }
  .fa-times {
    font-size: 1.2rem;
  }

  .fa-bars {
    font-size: 1.2rem;
  }

  .start-online-visit {
    padding: 10px 15px;
    font-size: 14px;
  }
  .nav-links {
    font-size: 14px;
  }
  .dropdown {
    align-items: flex-start;
  }
  .show {
    gap: 0;
    align-items: flex-start;
    margin-top: 5px;
  }
}
.hidden-until-mobile {
  display: none;
}

@media screen and (max-width: $medium-mobile) {
  .navbar-logo img {
    width: 110px;
  }
  .fa-times {
    font-size: 1.5rem;
  }

  .fa-bars {
    font-size: 1.5rem;
  }

  .hidden-mobile {
    display: none !important;
  }

  .hidden-until-mobile {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    color: $secondary-accent;
  }
}
