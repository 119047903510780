@import "./components/variables.scss";
html {
  scroll-behavior: smooth;
  height: 100%;
}
@media screen and (min-width: $medium-tablet) {
  html {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    height: 100%;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Source Sans 3", sans-serif;
}
body {
  // scroll-snap-align: center;
  scroll-snap-stop: normal;
  overflow: auto;
  height: 100%;
  overscroll-behavior: none;
}

.home .services,
.products,
.sign-up .hair-loss .navbar {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.w-md-editor-text-input,
.w-md-editor-text-pre > code,
.w-md-editor-text-pre,
.w-md-editor-text-pre .title,
.w-md-editor {
  // line-height: unset !important;
  font-family: inherit !important;
  // white-space: pre-wrap;
}
body .w-md-editor-text-pre > code,
body .w-md-editor-text-input {
  font-size: 15px !important;
  line-height: 24px !important;
  font-family: inherit;
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: $primary-accent;
  transform-origin: 0%;
  z-index: 100000;
  border-radius: 10em;
}
